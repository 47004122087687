const header = {
  homepage: 'https://inaeem.online',
  title: 'iNaeem',
};

const about = {
  name: 'Ahmed Naeem',
  role: 'Supply Chain Planner | BI Analyst',
  description:
    'Experienced Supply Chain Planner and Business Analyst with 8 years across various industries, including pharmaceuticals, FMCG, electronics, and printing. Skilled in demand forecasting, inventory control, production planning, and performance reporting. Recognized for strong analytical skills and ERP proficiency. Consistently improves supply chain performance and operational efficiency using AI-driven planning and business intelligence tools.',
  resume: 'https://drive.google.com/file/d/1K7vbogh1O8bKOxwQMrnVIVdUkTcbIdoz/view?usp=share_link',
  social: {
    linkedin: 'https://www.linkedin.com/in/abdelnaeem/',
    github: 'https://github.com/ahmednaeem13',
  },
};

const projects = [
  {
    name: 'US Power Outage Analysis (2002-2023)',
    description:
      'An analysis of power outages across the United States from 2002 to 2023, highlighting trends and patterns in power disruptions.',
    stack: ['Power BI', 'Data Analysis'],
    livePreview:
      'https://app.powerbi.com/view?r=eyJrIjoiMDlhYjg4MTEtNGE5Mi00ZDNjLWJiYzUtZjA2NGZmNmVhNTFmIiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9',
  },
  {
    name: 'Comprehensive Sales Performance Analysis',
    description:
      'A comprehensive analysis of sales performance across channels and categories, covering revenue, orders, and ATP.',
    stack: ['Power BI', 'Data Analysis'],
    livePreview:
      'https://app.powerbi.com/view?r=eyJrIjoiNDgzMThkNDQtNmQxMi00MzRjLTkwMzQtOGQwMThhNjE1OGVmIiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9',
  },
  {
    name: 'Revenue and Profit Margin Analysis',
    description:
      'An interactive tool to analyze revenue and profit margins, providing insights on supplier, team, and sales performance by category.',
    stack: ['Power BI', 'Data Analysis'],
    livePreview:
      'https://app.powerbi.com/view?r=eyJrIjoiZWRkMDU2NWUtMzRkNi00N2E4LWJkNDctZjA0ZjkzZjI3ZjQ2IiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9',
  },
  {
    name: 'Purchase Operations Analysis',
    description:
      'Detailed purchase operations analysis over the years, tracking orders and purchase requisitions across purchasing groups and currencies.',
    stack: ['Power BI', 'Data Analysis'],
    livePreview:
      'https://app.powerbi.com/view?r=eyJrIjoiZjI3YTEwZTMtZWRlYi00Y2VhLWFmMTQtYmM5NDkyNjgwZDg4IiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9',
  },
  {
    name: 'User Fitness Data Analysis',
    description:
      'An interactive dashboard displaying fitness data such as calories, steps, stairs climbed, and various fitness activities using FitBit data.',
    stack: ['Power BI', 'Data Analysis'],
    livePreview:
      'https://app.powerbi.com/view?r=eyJrIjoiMzk4NDI5ZWUtZDE4Zi00OTBhLWFjOTAtZTMwNmQ1ZDJmMGQ2IiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9',
  },
  {
    name: 'Sales Performance by Product and Country',
    description:
      'An interactive dashboard to analyze sales performance by unit, product, category, and country, covering sales, profits, and costs over time.',
    stack: ['Power BI', 'Data Analysis'],
    livePreview:
      'https://app.powerbi.com/view?r=eyJrIjoiNmM5YmQ3YjktODkwZS00MDU0LWE3MjctNzYxYTA5MzUxMTc5IiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9',
  },
  {
    name: 'Titanic Passenger Data Analysis',
    description:
      "An interactive 'Titanic Dashboard' providing an overview of passenger survival and fatalities by age, gender, class, and passenger status.",
    stack: ['Power BI', 'Data Analysis'],
    livePreview:
      'https://app.powerbi.com/view?r=eyJrIjoiNjVjMDU2NTEtOTJiNS00MTQxLTg3MGEtZDhlNDU3ZWViZDBhIiwidCI6IjA3NTQwNmZlLWYzMGMtNGM5NC05ZjQzLWU2M2ViMzY5NzZiNiIsImMiOjh9',
  },
];

const skills = [
  'Supply Chain Performance Metrics',
  'MRP',
  'Inventory Optimization',
  'Production Planning',
  'Monitoring Stock Levels',
  'Analytical Thinking',
  'Data Analysis',
  'Planning Delivery Timetables',
  'Power BI',
  'AI-Enhanced Planning Techniques',
  'Microsoft Excel',
  'Power Query',
  'Power Pivot',
  'GPT',
  'SAP',
  'Oracle',
  'Microsoft Access',
  'Tableau',
  'Python',
  'SQL Database',
];

const contact = {
  email: 'inaeem.online@gmail.com',
};

export { header, about, projects, skills, contact };
