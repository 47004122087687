import React, { useState } from 'react'
import uniqid from 'uniqid'
import { projects } from '../../portfolio'
import './Projects.css'

const ProjectContainer = ({ project }) => {
  const [isFullscreen, setFullscreen] = useState(false)

  const toggleFullscreen = () => setFullscreen(!isFullscreen)

  return (
    <div className={`project ${isFullscreen ? 'fullscreen' : ''}`}>
      <h3 className='project__title'>{project.name}</h3>
      <p className='project__description'>{project.description}</p>

      <div className='project__iframe-container'>
        <iframe
          src={project.livePreview}
          title={`project-${project.name}`}
          frameBorder='0'
          allowFullScreen
          className='project__iframe'
        />
      </div>

      <button
        type='button'
        className='project__button'
        onClick={toggleFullscreen}
      >
        {isFullscreen ? 'Exit Fullscreen' : 'View Fullscreen'}
      </button>
    </div>
  )
}

const Projects = () => {
  if (!projects.length) return null

  return (
    <section id='projects' className='section projects'>
      <h2 className='section__title'>Power BI Projects</h2>

      <div className='projects__grid'>
        {projects.map((project) => (
          <ProjectContainer key={uniqid()} project={project} />
        ))}
      </div>
    </section>
  )
}

export default Projects
